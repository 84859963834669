export const TRUSTED_SITES_TABLE_KEY = 'trustedSites';
export const TRUSTED_SITES_SITE_ID_COL = 'siteId';
export const TRUSTED_SITES_NAME_COL = 'siteName';

export const FEATURED_RELEASES_TABLE_KEY = 'featuredReleases';
export const FEATURED_RELEASES_RELEASE_ID_COLUMN = 'releaseId';
export const FEATURED_RELEASES_START_TIME_COLUMN = 'startTime';
export const FEATURED_RELEASES_END_TIME_COLUMN = 'endTime';

export const BLOCKED_RELEASES_TABLE_KEY = 'blockedReleases';
export const BLOCKED_RELEASES_RELEASE_ID_COLUMN = 'releaseId';

export const RELEASES_FILE_COLUMN = 'file';
export const RELEASES_AUTHOR_COLUMN = 'author';
export const RELEASES_NAME_COLUMN = 'contentName';
export const RELEASES_METADATA_COLUMN = 'metadata';
export const RELEASES_THUMBNAIL_COLUMN = 'thumbnail';
export const RELEASES_CATEGORY_COLUMN = 'category';
export const RELEASES_STATUS_COLUMN = 'status';
export const RELEASES_COVER_COLUMN = 'cover';

export const COLLECTIONS_RELEASES_COLUMN = 'releases';
export const COLLECTIONS_AUTHOR_COLUMN = 'author';
export const COLLECTIONS_NAME_COLUMN = 'contentName';
export const COLLECTIONS_METADATA_COLUMN = 'metadata';
export const COLLECTIONS_THUMBNAIL_COLUMN = 'thumbnail';
export const COLLECTIONS_CATEGORY_COLUMN = 'category';
export const COLLECTIONS_STATUS_COLUMN = 'status';

export const RELEASES_DB_TABLE_KEY = 'releases';
export const COLLECTIONS_DB_TABLE_KEY = 'collections';
